import { Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Layout } from './components/layouts/Layout';
import './assets/css/main.css';
import ScrollToTop from './components/ScrollToTop';
import { FAQ } from './pages/FAQ';
import { BSD } from './pages/BSD';
import { NotFound } from './pages/NotFound';
const Company = lazy(() =>
  import('./pages/Company').then((module) => ({
    default: module.Company,
  }))
);
const Contact = lazy(() =>
  import('./pages/Contact').then((module) => ({
    default: module.Contact,
  }))
);

const Process = lazy(() =>
  import('./pages/Process').then((module) => ({
    default: module.Process,
  }))
);
const Services = lazy(() =>
  import('./pages/Services').then((module) => ({
    default: module.Services,
  }))
);
const StemOutreach = lazy(() =>
  import('./pages/STEM_Outreach').then((module) => ({
    default: module.StemOutreach,
  }))
);
const TurnKey = lazy(() =>
  import('./pages/TurnKey').then((module) => ({
    default: module.TurnKey,
  }))
);
const WeFit = lazy(() =>
  import('./pages/WeFit').then((module) => ({
    default: module.WeFit,
  }))
);
const WorkForce = lazy(() =>
  import('./pages/WorkForce').then((module) => ({
    default: module.WorkForce,
  }))
);
const WindTurbine = lazy(() =>
  import('./pages/WindTurbine').then((module) => ({
    default: module.WindTurbine,
  }))
);
const Bridge = lazy(() =>
  import('./pages/Bridge').then((module) => ({
    default: module.Bridge,
  }))
);
const SolarEnergy = lazy(() =>
  import('./pages/SolarEnergy').then((module) => ({
    default: module.SolarEnergy,
  }))
);
const Racecar = lazy(() =>
  import('./pages/Racecar').then((module) => ({
    default: module.Racecar,
  }))
);
const Tower = lazy(() =>
  import('./pages/Tower').then((module) => ({
    default: module.Tower,
  }))
);
const GliderPlane = lazy(() =>
  import('./pages/GliderPlane').then((module) => ({
    default: module.GliderPlane,
  }))
);
const Prosthetics = lazy(() =>
  import('./pages/Prosthetics').then((module) => ({
    default: module.Prosthetics,
  }))
);
const Hydraulics = lazy(() =>
  import('./pages/Hydraulics').then((module) => ({
    default: module.Hydraulics,
  }))
);
const MeetTeam = lazy(() =>
  import('./pages/MeetTeam').then((module) => ({
    default: module.MeetTeam,
  }))
);
const Community = lazy(() =>
  import('./pages/Community').then((module) => ({
    default: module.Community,
  }))
);
const Media = lazy(() =>
  import('./pages/Media').then((module) => ({
    default: module.Media,
  }))
);
const Legal = lazy(() =>
  import('./pages/Legal').then((module) => ({ default: module.Legal }))
);
const AnimalHeroes = lazy(() =>
  import('./pages/AnimalHeroes').then((module) => ({
    default: module.AnimalHeroes,
  }))
);
const VRdriving_Corporate_Outreach = lazy(() =>
  import('./pages/Vrdriving_Corporate_Outreach').then((module) => ({
    default: module.VRdriving_Corporate_Outreach,
  }))
);
const CSR = lazy(() =>
  import('./pages/CSR').then((module) => ({
    default: module.CSR,
  }))
);
const LoadStatus = () => {
  return (
    <div className="flex items-center justify-center w-full min-h-screen bg-gray-200">
      <div className="flex items-center justify-center w-full min-h-screen bg-gray-50">
        <div className="flex items-center justify-center rounded-full h-14 w-14 bg-gradient-to-tr from-gray-50 to-blue-500 animate-spin">
          <div className="rounded-full h-9 w-9 bg-gray-50"></div>
        </div>
      </div>
    </div>
  );
};
function App() {
  return (
    <>
      <Suspense fallback={<LoadStatus />}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index path="" element={<Home />} />
              <Route index path="/Home" element={<Home />} />
              <Route path="company" element={<Company />} />
              <Route
                path="stem_corporate_outreach"
                element={<StemOutreach />}
              />
              <Route path="cs_corporate_outreach" element={<TurnKey />} />
              <Route path="contact" element={<Contact />} />
              <Route path="process" element={<Process />} />
              <Route path="services" element={<Services />} />
              <Route path="are_we_a_fit" element={<WeFit />} />
              <Route path="ar_vr_custom_development" element={<WorkForce />} />
              <Route path="wind_turbine" element={<WindTurbine />} />
              <Route path="bridge" element={<Bridge />} />
              <Route path="solar_energy" element={<SolarEnergy />} />
              <Route path="racecar" element={<Racecar />} />
              <Route path="tower" element={<Tower />} />
              <Route path="glider_plane" element={<GliderPlane />} />
              <Route path="prosthetics" element={<Prosthetics />} />
              <Route path="hydraulics" element={<Hydraulics />} />
              <Route path="meet_the_team" element={<MeetTeam />} />
              <Route path="community" element={<Community />} />
              <Route path="media" element={<Media />} />
              <Route path="legal" element={<Legal />} />
              <Route path="csr" element={<CSR />} />
              <Route path="bsd" element={<BSD />} />
              <Route path="faq" element={<FAQ />} />
              <Route
                path="animal_heroes/privacy_policy"
                element={<AnimalHeroes />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
